var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showError,
          expression: "showError"
        }
      ],
      staticClass: "m-error-wrap",
      class: _vm.cssList
    },
    [
      _c("div", { staticClass: "m-error" }, [
        _vm.showIcon ? _c("i", { class: ["m-icon--" + _vm.type] }) : _vm._e(),
        _vm._v(" "),
        _vm.msg
          ? _c("p", {
              staticClass: "m-text-info",
              domProps: { innerHTML: _vm._s(_vm.msg) }
            })
          : _c("p", { staticClass: "m-text-info" }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }