var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AfButton",
    {
      class: _vm.getBtnCls,
      attrs: { extraType: "info", disabled: !_vm.isReady },
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleClick($event)
        }
      }
    },
    [_vm._v(_vm._s(_vm.btnText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }