var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "m-dialog-wrapper",
        {
          "m-dialog-wrapper-fix": _vm.isShare
        }
      ]
    },
    [
      _c("div", { staticClass: "m-pop-header" }, [
        _c("span", [_vm._v(_vm._s(_vm.contentData.title))]),
        _vm._v(" "),
        _c("i", {
          staticClass: "m-icon-close",
          attrs: { "data-action": "close", "data-ret": "close" }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-pop-main" },
        [
          _vm.isShare
            ? _c("p", { staticClass: "share-code-title" }, [
                _vm._v(_vm._s(_vm.i18n.t("share:code:text")))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.contentData.msg) } }),
          _vm._v(" "),
          _vm.isShare
            ? [
                _c("div", { staticClass: "m-share-wrapper" }, [
                  _c("div", { staticClass: "m-label" }, [
                    _vm._v(_vm._s(_vm.i18n.t("booking:number:label")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-value" }, [
                    _vm._v(_vm._s(_vm.contentData.shareCode)),
                    _vm.isHaveCopy
                      ? _c(
                          "i",
                          {
                            ref: "copyShareCode",
                            attrs: {
                              "data-clipboard-text": _vm.contentData.shareCode
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.t("copy:label")))]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            : [
                _c(
                  "div",
                  { staticClass: "m-btn-wrapper" },
                  [
                    _vm.shouldGoDeposit
                      ? [
                          _c(
                            "af-Button",
                            {
                              attrs: {
                                extraType: "text",
                                "data-action": "close",
                                "data-ret": "close"
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.t("cancel:label")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "af-Button",
                            {
                              attrs: {
                                "data-action": "close",
                                "data-ret": "close"
                              },
                              on: { click: _vm.goDeposit }
                            },
                            [_vm._v("Deposit")]
                          )
                        ]
                      : [
                          _c(
                            "af-Button",
                            {
                              attrs: {
                                "data-action": "close",
                                "data-ret": "close"
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.t("ok:label")))]
                          )
                        ]
                  ],
                  2
                )
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }