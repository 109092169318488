var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPop
    ? _c("div", { staticClass: "m-pop-wrapper" }, [
        _c("div", { staticClass: "m-pop-bg" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-pop-mian" },
          [
            _c(
              "SlideAnimation",
              { attrs: { pageCount: 3, activeIndex: _vm.moduleIndex } },
              [
                _c(
                  "SlideItem",
                  { attrs: { pageIndex: 0 } },
                  [
                    _c("LoginHome", {
                      attrs: {
                        errorInfo: _vm.errorInfo,
                        account: _vm.getAccount,
                        countryCode: _vm.countryCode,
                        loading: _vm.loading,
                        isPop: true,
                        activeTab: _vm.activeTab
                      },
                      on: {
                        submit: _vm.goNext,
                        closePop: _vm.handleClose,
                        changeTab: _vm.handleToggle
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.showSMSPage
                  ? _c(
                      "SlideItem",
                      { attrs: { pageIndex: 1 } },
                      [
                        _c("LoginCode", {
                          attrs: {
                            phone: _vm.getAccount,
                            countryCode: _vm.countryCode,
                            errorInfo: _vm.errorInfo,
                            remainCount: _vm.remainCount,
                            loading: _vm.loading,
                            needReset: _vm.moduleIndex === 1,
                            isStartTimer: _vm.startTimer
                          },
                          on: {
                            submit: _vm.goNext,
                            back: _vm.goPrev,
                            closePop: _vm.handleClose
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "SlideItem",
                      { attrs: { pageIndex: 1 } },
                      [
                        _c("LoginSMS", {
                          attrs: {
                            phone: _vm.getAccount,
                            countryCode: _vm.countryCode,
                            smsNumber: _vm.smsNumber,
                            smsContent: _vm.smsContent,
                            loading: _vm.loading,
                            needReset: _vm.moduleIndex === 1,
                            moduleName: _vm.moduleName
                          },
                          on: {
                            submit: _vm.goNext,
                            closePop: _vm.handleClose,
                            back: _vm.goPrev
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "SlideItem",
                  { staticClass: "m-item-suc", attrs: { pageIndex: 2 } },
                  [
                    _c("LoginSuc", {
                      attrs: {
                        promotionAd: _vm.promotionAd,
                        referralCode: _vm.referralCode,
                        loading: _vm.loading,
                        errorInfo: _vm.errorInfo,
                        sucCfg: _vm.sucCfg
                      },
                      on: {
                        submit: _vm.goNext,
                        closePop: _vm.handleClose,
                        back: _vm.goPrev
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }