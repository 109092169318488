var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-page m-page--login" },
    [
      _vm.hasHeader
        ? _c("AfHeader", {
            attrs: { backIcon: false },
            on: { close: _vm.closePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "m-page-main" }, [
        false
          ? _c(
              "div",
              { staticClass: "m-tab-wrapper" },
              [
                _c(
                  "AfTabs",
                  {
                    staticClass: "m-page-nav",
                    attrs: { value: _vm.type },
                    on: { change: _vm.handleToggle }
                  },
                  [
                    _c("AfTabPane", {
                      attrs: { label: _vm.registerText, name: _vm.registerText }
                    }),
                    _vm._v(" "),
                    _c("AfTabPane", {
                      attrs: { label: _vm.loginText, name: _vm.loginText }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-opt-wrapper" },
          [
            _vm.tipsInfo
              ? _c("Tips", {
                  attrs: {
                    type: _vm.tipsInfo.type || "",
                    msg: (_vm.tipsInfo && _vm.tipsInfo.msg) || ""
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-page-opt" },
              [
                _c("AfInput", {
                  attrs: {
                    placeholder: _vm.i18n.t("user:name"),
                    icon: "delete",
                    error: _vm.phoneError || _vm.isPhoneError,
                    iconClick: _vm.clearAllInput
                  },
                  on: {
                    change: _vm.validatorPhone,
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleClick($event)
                    }
                  },
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone"
                  }
                }),
                _vm._v(" "),
                _c(
                  "AfInput",
                  {
                    attrs: {
                      type: "password",
                      placeholder: _vm.getPsdPlaceholder,
                      toggleShowText: true,
                      error: _vm.passwordError || _vm.isPsdError,
                      initTextIconValue: _vm.initTextIconValue
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleClick($event)
                      },
                      change: _vm.validatorPsd
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  },
                  [
                    _vm.type === _vm.registerText
                      ? _c(
                          "PopOver",
                          {
                            attrs: {
                              position: "bottom",
                              isCenter: false,
                              arrowCenter: false,
                              triggerType: "click",
                              width: "260px"
                            }
                          },
                          [
                            _c("p", [
                              _vm._v("Length of 6-14 characters."),
                              _c("br"),
                              _vm._v("At least one letter and one number.")
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "m-btn-wrap",
                      {
                        "m-btn-wrap-fix": _vm.type === _vm.registerText
                      }
                    ]
                  },
                  [
                    _vm.isLogin
                      ? _c(
                          "AfButton",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: _vm.submitStatus
                            },
                            on: { click: _vm.handleClick }
                          },
                          [_vm._v(_vm._s(_vm.i18n.t("login:label")))]
                        )
                      : _c(
                          "AfButton",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: _vm.submitStatus
                            },
                            on: { click: _vm.handleClick }
                          },
                          [_vm._v("Create  Account")]
                        )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        false
          ? _c("div", { staticClass: "m-info-wrapper" }, [
              _c(
                "span",
                { staticClass: "m-log-third", on: { click: _vm.openIDOAuth } },
                [
                  _c("i", { staticClass: "m-icon-facebook" }),
                  _vm._v("Login with the Facebook")
                ]
              ),
              _vm._v(" "),
              !_vm.isLogin
                ? _c("p", { staticClass: "m-text-tips" }, [
                    _vm._v(
                      "\n\t\t\t\tBy creating an account, you agree to our "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "m-text-highlight",
                        attrs: {
                          href: _vm.helpUrl + "?nav=terms-and-conditions"
                        }
                      },
                      [_vm._v("Terms & Conditions")]
                    ),
                    _vm._v(
                      " and confirm that you are at least 18 years old.\n\t\t\t"
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }