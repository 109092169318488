var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "m-balance-tips",
        {
          on: _vm.showTips
        }
      ]
    },
    [_c("p", [_vm._v("Check your bet history here")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }