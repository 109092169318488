// import invert from 'utils/invert';
import { i18n } from 'locale/setup';
// sportName 空格移除，首字母小写即可； e.g. Beach Volley => beachVolley
// 转换后可以用在url里面
export const getSportRoute = sportName => {
	let name = sportName.trim();
	// 移除空格
	name = name.split(' ');
	name = name.map((n, index) => {
		if (index > 0) {
			return `${n[0].toUpperCase()}${n.substring(1)}`;
		}
		return `${n[0].toLowerCase()}${n.substring(1)}`;
	});

	return name.join('');
};

let sportList;
// nav列表，保持顺序
const getSportList = () => {
	if (!sportList) {
		sportList = [{
			id: '1',
			name: 'Soccer',
			title: i18n.t('sport:name:1')
		}, {
			id: '2',
			name: 'Basketball',
			title: i18n.t('sport:name:2')
		}, {
			id: '5',
			name: 'Tennis',
			title: i18n.t('sport:name:5')
		}, {
			id: 23,
			name: 'Volleyball',
			title: i18n.t('sport:name:23')
		}, {
			id: 3,
			name: 'Baseball',
			title: i18n.t('sport:name:3')
		}, {
			id: 4,
			name: 'Ice Hockey',
			title: i18n.t('sport:name:4')
		}, {
			id: 16,
			name: 'Football',
			title: i18n.t('sport:name:16')
		}, {
			id: 31,
			name: 'Badminton',
			title: i18n.t('sport:name:31')
		}, {
			id: '12',
			name: 'Rugby',
			title: i18n.t('sport:name:12')
		}, {
			id: 6,
			name: 'Handball',
			title: i18n.t('sport:name:6')
		}, {
			id: 20,
			name: 'Table Tennis',
			title: i18n.t('sport:name:20')
		}, {
			id: '21',
			name: 'Cricket',
			title: i18n.t('sport:name:21')
		}, {
			id: 29,
			name: 'Futsal',
			title: i18n.t('sport:name:29')
		}, {
			id: 34,
			name: 'Beach Volley',
			title: i18n.t('sport:name:34')
		}, {
			id: 13,
			name: 'Aussie Rules',
			title: i18n.t('sport:name:13')
		}, {
			id: 24,
			name: 'Field Hockey',
			title: i18n.t('sport:name:24')
		}, {
			id: 37,
			name: 'Squash',
			title: i18n.t('sport:name:37')
		}, {
			id: 32,
			name: 'Bowls',
			title: i18n.t('sport:name:32')
		}, {
			id: 19,
			name: 'Snooker',
			title: i18n.t('sport:name:19')
		}, {
			id: 22,
			name: 'Darts',
			title: i18n.t('sport:name:22')
		}];
	}
	return sportList;
};

let sportConfig;

const getSportConfig = () => {
	if (!sportConfig) {
		const sportLower = {};
		const sportUpper = {};
		const nameToIdMap = {};
		const sportOrder = {};  // nav tab 排序
		const nameToSportMap = {};
		const idToSportMap = {};
		const sportListUpper = [];
		getSportList().forEach((sport, index) => {
			const routeName = getSportRoute(sport.name);
			const sportId = `sr:sport:${sport.id}`;

			sport.icon = require(`./sport-icon/${routeName.toLowerCase()}.png`);
			sport.colorIcon = require(`./sport-icon/${routeName.toLowerCase()}-color.png`);
			sport.hoverIcon = require(`./sport-icon/${routeName.toLowerCase()}-hover.png`);
			sportLower[sport.id] = routeName;  // 原因是 以前就用sportConfigLowerCase[sportId] 来拼接路由
			// 加入sr:sport:id, 减少slice(9)
			sportLower[sportId] = routeName;
			sportUpper[sport.id] = sport.name;
			sportUpper[sportId] = sport.name;
			nameToSportMap[routeName] = sport;
			idToSportMap[sportId] = sport;
			nameToIdMap[routeName] = sport.id;
			nameToIdMap[routeName.toLowerCase()] = sport.id;  // 应对路由处理，遗留
			sportListUpper[index] = sport;
			// order
			sportOrder[sport.id] = index;
			sportOrder[sportId] = index;
		});
		sportConfig = {
			idToSportMap,
			nameToSportMap,
			sportUpper,
			sportLower,
			nameToIdMap,
			sportListUpper,
			sportOrder,
		};
	}
	return sportConfig;
};

export const getIdToSport = () => getSportConfig().idToSportMap;
export const getNameToSport = () => getSportConfig().nameToSportMap;
// Upper Map
export const getSportConfigUpperCase = () => getSportConfig().sportUpper;

export const getSportListUpperCase = () => getSportConfig().sportListUpper;
// Lower Map [key=route]
export const getSportConfigLowerCase = () => getSportConfig().sportLower;

// 根据name查找id
export const getSportIdByName = sportName => {
	const name = getSportRoute(sportName);
	return getSportConfig().nameToIdMap[name];
};
export const getSportConfigOrder = () => getSportConfig().sportOrder;

// prematch stats
export const trackerSports = [1, 2, 5, 6, 22];

// live match tracer
export const liveTrackers = [1, 2, 4, 5, 6, 22, 23, 34];

export const DoubleScoreSports = [23, 34]; // 显示两个比分: set score ， game score; volleyball beach volley
export const TribleScoreSports = [5]; // 显示三场比分， set, game, point ; tennis

export const statisticsSports = [1, 4, 6, 22, 23, 34]; // live single view 展示statistics

export const foldThreshold = 9; // 首页体育导航栏的体育运动数目， 多的放到more里

// Map[id]， 获取id
export const getSportType2Id = () => getSportConfig().nameToIdMap;
