var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-page m-page--success" },
    [
      _vm.hasHeader
        ? _c("AfHeader", {
            attrs: { backIcon: _vm.canBack },
            on: { back: _vm.goPrev, close: _vm.closePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "m-page-main" }, [
        _c("h3", { staticClass: "m-icon-success" }, [
          _vm._v("Registration Successful")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-desc-wrapper" }, [
          _c("p", { staticClass: "m-t-big" }, [
            _vm._v(_vm._s(_vm.sucTips.main))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "m-t-min" }, [
            _vm._v(_vm._s(_vm.sucTips.desc))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-btn-wrapper" },
          [
            _c("AfButton", { on: { click: _vm.deposit } }, [
              _vm._v("Go to Deposit " + _vm._s(_vm.dpDesc))
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "m-page-footer" },
        [
          _vm.referralCode
            ? [
                _c("div", { staticClass: "m-page-opt" }, [
                  _c("p", { staticClass: "m-text-tips" }, [
                    _vm._v("Referral code")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "m-code m-border" }, [
                    _vm._v(_vm._s(_vm.referralCode))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-opt-wrapper" },
                    [
                      _c(
                        "AfButton",
                        {
                          attrs: { extraType: "text" },
                          on: { click: _vm.goHome }
                        },
                        [
                          _vm._v("Back"),
                          _c("i", { staticClass: "m-icon-arrow-right" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            : [
                _c("div", { staticClass: "m-page-opt" }, [
                  _c("p", { staticClass: "m-text-tips" }, [
                    _vm._v("Have a referral code?")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-opt-wrapper m-border" },
                    [
                      _c("AfInput", {
                        attrs: {
                          placeholder: "Referral Code (OPTIONAL)",
                          icon: "delete",
                          iconClick: _vm.clearAllInput,
                          error: _vm.codeError || _vm.isCodeError
                        },
                        model: {
                          value: _vm.code,
                          callback: function($$v) {
                            _vm.code = $$v
                          },
                          expression: "code"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "AfButton",
                        {
                          attrs: {
                            extraType: "info",
                            loading: _vm.loading,
                            disabled: _vm.submitStatus
                          },
                          on: { click: _vm.apply }
                        },
                        [_vm._v("Apply")]
                      ),
                      _vm._v(" "),
                      _vm.showError
                        ? _c("p", { staticClass: "m-error-tips" }, [
                            _vm._v(_vm._s(_vm.showError))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-opt-wrapper" },
                    [
                      _c(
                        "AfButton",
                        {
                          attrs: { extraType: "text" },
                          on: { click: _vm.goHome }
                        },
                        [
                          _vm._v("Back"),
                          _c("i", { staticClass: "m-icon-arrow-right" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }