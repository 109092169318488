<template lang="html">
	<div :class="[
			'm-dialog-wrapper',
			{
				'm-dialog-wrapper-fix': isShare
			}
		]"
	>
		<div class="m-pop-header">
			<span>{{contentData.title}}</span>
			<i
				class="m-icon-close"
				data-action="close"
				data-ret="close"
			></i>
		</div>
		<div class="m-pop-main">
			<p v-if="isShare" class="share-code-title">{{i18n.t('share:code:text')}}</p>
			<p v-html="contentData.msg"></p>

			<!-- share bet code success -->
			<template v-if="isShare">
				<div class="m-share-wrapper">
					<div class="m-label">{{i18n.t('booking:number:label')}}</div>
					<div class="m-value">{{contentData.shareCode}}<i v-if="isHaveCopy" ref="copyShareCode" :data-clipboard-text="contentData.shareCode">{{i18n.t('copy:label')}}</i></div>
				</div>
			</template>

			<template v-else>
				<div class="m-btn-wrapper">

					<!-- create order success -->
					<template v-if="shouldGoDeposit">
						<af-Button
							extraType="text"
							data-action="close"
							data-ret="close"
						>{{i18n.t('cancel:label')}}</af-Button>
						<af-Button
							data-action="close"
							data-ret="close"
							@click="goDeposit"
						>Deposit</af-Button>
					</template>

					<!-- common tips -->
					<template v-else>
						<af-Button
							data-action="close"
							data-ret="close"
						>{{i18n.t('ok:label')}}</af-Button>
					</template>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
import 'packages/button';
import 'packages/input';
import ClipboardJS from 'clipboard';
import { i18n } from 'locale/setup';
import { userCenterConfig } from 'config/order/userCenterConfig';

export default {
	data() {
		return {
			i18n,
			isHaveCopy: document.execCommand
		};
	},
	computed: {
		shouldGoDeposit() {
			return this.contentData.optType === 'deposit';
		},
		isShare() {
			return this.contentData.optType === 'share';
		}
	},
	methods: {
		goDeposit() {
			window.open(userCenterConfig.Deposit, '_blank');
		},
		copyCode() {
			const codeWrapperDom = document.querySelector('#j_betCode'),
				codeDom = codeWrapperDom && codeWrapperDom.querySelector('.m-input') || null;

			if (codeDom) {
				codeDom.select();
				document.execCommand('Copy');
			}
		}
	},
	mounted() {
		if (this.$refs.copyShareCode) {
			this.clipboard = new ClipboardJS(this.$refs.copyShareCode);
			this.clipboard.on('success', e => {
				this.$toast(i18n.t('copy:success:label'));
				e.clearSelection();
			});
		}
	}
};
</script>
