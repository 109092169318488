var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "div",
      { ref: "nav", class: ["m-tabs-nav"], style: _vm.navStyle || {} },
      [
        _c("div", { class: _vm.barClasses, style: _vm.barStyle }),
        _vm._v(" "),
        _vm._l(_vm.navList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: _vm.tabCls(item),
              on: {
                click: function($event) {
                  _vm.handleChange(index)
                }
              }
            },
            [
              item.icon !== ""
                ? _c("i", { attrs: { type: item.icon } })
                : _vm._e(),
              _vm._v(" "),
              item.labelType === "function"
                ? _c("Render", { attrs: { render: item.label } })
                : [_vm._v(_vm._s(item.label))]
            ],
            2
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.contentClasses, style: _vm.contentStyle },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }