var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getWrapperClass, attrs: { tabindex: "8" } },
    [
      _vm.prepend
        ? _c("div", { staticClass: "m-input-prepend" }, [_vm._t("prepend")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "m-input-com" }, [
        _vm.icon
          ? _c("i", {
              class: [
                "m-input-icon",
                {
                  "m-input-icon--clickable": _vm.iconClickable,
                  "m-input-icon--show": _vm.showIcon
                },
                "m-icon-" + _vm.icon
              ],
              on: { click: _vm.handleIconClick }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showText && _vm.textIconValue
          ? _c(
              "i",
              {
                staticClass: "m-input-text--icon",
                on: { click: _vm.toggleShowVal }
              },
              [_vm._v(_vm._s(_vm.getShowTextIcon()))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showText && !_vm.textIconValue
          ? _c(
              "i",
              {
                staticClass: "m-input-text--icon",
                on: { click: _vm.toggleShowVal }
              },
              [_vm._v(_vm._s(_vm.getHideTextIcon()))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isInput
          ? _c("input", {
              ref: "input",
              staticClass: "m-input",
              attrs: {
                type: "input",
                name: _vm.name,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                readonly: _vm.readonly,
                maxlength: _vm.maxlength,
                min: _vm.minlength,
                onpaste: _vm.getPasteHanle
              },
              domProps: { value: _vm.currentValue },
              on: { input: _vm.handlInput, focus: _vm.handleFocus }
            })
          : _c("input", {
              ref: "input",
              staticClass: "m-input",
              attrs: {
                type: "password",
                name: _vm.name,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                readonly: _vm.readonly,
                maxlength: _vm.maxlength,
                min: _vm.minlength,
                onpaste: _vm.getPasteHanle
              },
              domProps: { value: _vm.currentValue },
              on: { input: _vm.handlInput, focus: _vm.handleFocus }
            })
      ]),
      _vm._v(" "),
      _vm.append
        ? _c("div", { staticClass: "m-input-append" }, [_vm._t("append")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }