import md5 from "blueimp-md5";
import { LS } from "storage";
import { formatNumber } from "utils";

import { EventBus } from "kernel/js/eventBus.js";
import commonEvent from "config/eventConfig/commonEvent";
import { pagePath } from "config/pageConfig";
import { getShowCurrency } from "config/currencyConfig";

import "../../../mockData/user/account?debug";
import "../../../mockData/user/logout?debug";
import "../../../mockData/loginRegister/login?debug";

/*
获取用户账号余额
 */
export function getbalance() {
	return new Promise((resolve, reject) => {
		let showVal = "- - -",
			coins = 0;

		fetch("/payment/v1/finAccs/finAcc/userBal/" + window.currency, {
			method: "GET"
		})
			.then(res => res.json())
			.then(data => {
				const code = data.code,
					originData = data.data || {};

				if (code === 10000) {
					typeof originData.avlBal === "number" &&
						(showVal = +originData.avlBal / 10000);
					typeof originData.avlCoins === "number" &&
						(coins = +originData.avlCoins / 10000);

					EventBus.$emit(commonEvent.SYNC_ACCOUNT_BALANCE, {
						balance: showVal,
						coins,
						updateLet: "userInfo"
					});
				}

				resolve({
					showVal,
					coins
				});
			})
			.catch(() => {
				resolve({
					showVal,
					coins
				});
			});
	});
}

function storeUserData(originData, uid) {
	LS.set("accessToken", originData.accessToken);
	LS.set("refreshToken", originData.refreshToken);
	LS.set("phone", originData.phone || uid);
	LS.set("userId", originData.userId);
	if (originData.deviceId) {
		LS.set("deviceId", originData.deviceId);
	}
}

function clearUserData() {
	LS.remove("accessToken");
	LS.remove("refreshToken");
	LS.remove("phone");
	LS.remove("userId");
}

/*
登录
 */
export function commitLogin(phone, psd) {
	return new Promise((resolve, reject) => {
		clearUserData();
		fetch("/user/login", {
			method: "POST",
			body: {
				username: phone,
				password: md5(psd)
			}
		})
			.then(res => res.json())
			.then(data => {
				const code = data.code,
					originData = data.data || {};

				if (code === 10000 && originData) {
					storeUserData(originData, phone);
					window.loginStatus = true;
					// // 广播登陆全局消息
					EventBus.$emit(commonEvent.UPDATE_LOGIN_STATUS);
					resolve({ login: true });
				} else {
					clearUserData();
					reject({
						code,
						msg:
							data.message || "No internet connection, try again."
					});
				}
			})
			.catch(() => {
				clearUserData();
				reject({
					msg: "No internet connection, try again."
				});
			});
	});
}

export function opLogin(op, token, uid) {
	console.log('opLogin-2222');
	return new Promise((resolve, reject) => {
		clearUserData();
		fetch("/user/account/opLogin", {
			method: "POST",
			body: {
				op: op,
				token: token,
				uid: uid
			}
		})
			.then(res => res.json())
			.then(ret => {
				const code = ret.code,
					originData = ret.data || {};

				if (code === 10000) {
					storeUserData(originData, uid);

					window.loginStatus = true;

					EventBus.$emit(commonEvent.UPDATE_LOGIN_STATUS);
					resolve({
						login: true
					});
				} else {
					clearUserData();
					reject({
						code,
						msg:
							data.message || "No internet connection, try again."
					});
				}
			})
			.catch(() => {
				clearUserData();
				resolve({ login: false });
			});
	});
}

/*
facebook login
 */
export function thirdPartyLogin(fbToken) {
	return new Promise((resolve, reject) => {
		fetch("/user/account/thirdParty", {
			method: "POST",
			body: {
				thirdPartyToken: fbToken || LS.get("fbToken")
			}
		})
			.then(res => res.json())
			.then(ret => {
				const code = ret.code,
					originData = ret.data || {};

				if (code !== 19999) {
					LS.delete("fbToken");
				}

				if (code === 10000) {
					LS.set("phone", originData.phone || "");
					LS.set("accessToken", originData.accessToken);
					LS.set("refreshToken", originData.refreshToken);
					LS.set("userId", originData.userId);
					if (originData.deviceId) {
						LS.set("deviceId", originData.deviceId);
					}

					// // 更新登录状态
					window.loginStatus = true;

					// // 广播登陆全局消息
					EventBus.$emit(commonEvent.UPDATE_LOGIN_STATUS);
					resolve({
						login: true
					});
				}

				// 未绑定手机号码
				if (code === 11608 && originData.token) {
					location.href = pagePath.fbLogin;
					LS.set("lgToken", originData.token);
				}
			})
			.catch(() => {
				LS.delete("fbToken");
				reject();
			});
	});
}
/*
退出
 */
export function commitLogout() {
	fetch("/user/logout", {
		method: "DELETE"
	})
		.then(res => res.json())
		.then(data => {
			const code = data.code;

			if (code === 10000) {
				LS.remove("accessToken");
				LS.remove("refreshToken");
				LS.remove("userId");
				LS.remove("phone");
				location.href = pagePath.home;
			}
		});
}

export function getShowBalance(isShow) {
	let showVal = +window.userBalance;

	if (isShow) {
		if (typeof showVal === "number" && !isNaN(showVal)) {
			// 0 显示成0.00
			showVal = getShowCurrency() + formatNumber(showVal, 2);
		} else {
			// 取不到余额显示成空
			showVal = "---";
		}
		return showVal;
	}

	return getShowCurrency() + "**** ";
}
