var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "af-button",
      class: [
        _vm.extraType ? "af-button--" + _vm.extraType : "",
        {
          "is-disabled": _vm.disabled,
          "is-loading": _vm.loading,
          "is-plain": _vm.plain
        }
      ],
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.handleClick }
    },
    [
      _vm.loading ? _c("i", { staticClass: "af-icon-loading" }) : _vm._e(),
      _vm._v(" "),
      _vm.icon && !_vm.loading
        ? _c("i", { class: "af-icon-" + _vm.icon })
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.default ? _c("span", [_vm._t("default")], 2) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }