var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "m-header-bar", class: _vm.cssList }, [
    _vm.backIcon
      ? _c("a", {
          staticClass: "m-icon m-icon-back",
          attrs: {
            href: "javascript:;",
            "data-action": "btn",
            "data-ret": "back"
          },
          on: { click: _vm.handleBack }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("span", { class: _vm.getTitleStyle }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _vm.closeIcon
      ? _c("span", {
          staticClass: "m-icon m-icon-close",
          attrs: { "data-action": "close", "data-ret": "close" },
          on: { click: _vm.handleClose }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }