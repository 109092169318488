import Vue from 'vue';
import { i18n } from 'locale/setup';

export const typeList = [
	'EU', // 欧洲盘
	'CN', // 中国盘
	'IND', // 印尼盘
	'MY', // 马来盘
	'US' // 美式盘
];

export const getTypeI18n = type => ({
	EU: i18n.t('odds:type:eu'),
	CN: i18n.t('odds:type:cn'),
	IND: i18n.t('odds:type:ind'),
	MY: i18n.t('odds:type:my'),
	US: i18n.t('odds:type:us')
});

export const EUTransFun = function(odds, type) { // 欧洲盘转成目标盘
	if (typeList.indexOf(type) < 0) {
		console.error(`Can't transform odds type: ${type}`);
		return 'xx';
	}
	const oddsValue = +odds;
	if (isNaN(oddsValue) || oddsValue === 0) {
		console.error(`Unvalidable odds: ${odds}`);
		return 'xx';
	}
	if (type === 'EU') {
		return oddsValue;
	}
	if (type === 'US') {
		return EU2US(oddsValue);
	}
	if (type === 'MY') {
		return EU2MY(oddsValue);
	}
	if (type === 'CN') {
		return EU2CN(oddsValue);
	}
	if (type === 'IND') {
		return EU2IND(oddsValue);
	}
};

function EU2US(odds) {
	let val;
	if (odds < 2) {
		val = 100 / (1 - odds);
	} else {
		val = 100 * (odds - 1);
	}
	return val.toFixed(0); // 美式带小数点太长了， 而且小数点精度没啥意义
}

function EU2MY(odds) {
	let val;
	if (odds < 2) {
		val = odds - 1;
	} else {
		val = 1 / (1 - odds);
	}
	return val.toFixed(2);
}

function EU2CN(odds) {
	let val;
	val = odds - 1;
	return val.toFixed(2);
}

function EU2IND(odds) { // 印尼盘乘100等于美式盘
	let val;
	if (odds < 2) {
		val = 100 / (1 - odds);
	} else {
		val = 100 * (odds - 1);
	}
	val /= 100;
	return val.toFixed(2);
}

if (!Vue.prototype.oddsTrans) {
	Vue.prototype.oddsTrans = EUTransFun;
}
