var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-pop-dialog",
      attrs: { slot: "popContent" },
      slot: "popContent"
    },
    [
      _c(
        "ul",
        { staticClass: "m-pop-dialog__wrapper", style: _vm.getContainerStyle },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }