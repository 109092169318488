var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-page m-page--code" },
    [
      _vm.hasHeader
        ? _c("AfHeader", {
            attrs: { backIcon: _vm.backIcon, title: _vm.titleText },
            on: { back: _vm.goPrev, close: _vm.closePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "m-page-main" }, [
        _c(
          "p",
          {
            class: [
              "m-text-tips",
              {
                "m-text--fix": _vm.pageError
              }
            ]
          },
          [_vm._v(_vm._s(_vm.getShowTips))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "m-page-opt" }, [
          _c(
            "div",
            { staticClass: "m-line" },
            [
              _c("AfInput", {
                attrs: {
                  placeholder: "Verification Code",
                  error: _vm.codeError || _vm.isCodeError,
                  icon: "delete",
                  iconClick: _vm.clearAllInput
                },
                on: { change: _vm.validatorCode },
                model: {
                  value: _vm.code,
                  callback: function($$v) {
                    _vm.code = $$v
                  },
                  expression: "code"
                }
              }),
              _vm._v(" "),
              _c("Timer", {
                attrs: {
                  isStartTimer: _vm.isStartTimer,
                  isStopTimer: _vm.isStopTimer
                },
                on: { timerClick: _vm.sendCode }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showError
            ? _c("p", { staticClass: "m-error-tips" }, [
                _vm._v(_vm._s(_vm.errorInfo.msg))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-btn-wrap" },
            [
              _c(
                "AfButton",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.goingNext,
                    disabled: _vm.submitStatus
                  },
                  on: { click: _vm.goNext }
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }