var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "ul",
        { staticClass: "m-user-center m-list" },
        [
          _vm.hasBalance
            ? _c("li", { staticClass: "m-list-bar m-balance" }, [
                _c("span", [_vm._v(_vm._s(_vm.balance))]),
                _vm._v(" "),
                _c("div", { staticClass: "m-icon-wrapper" }, [
                  _c("i", {
                    class: {
                      "m-icon-hide": _vm.showBalance,
                      "m-icon-show": !_vm.showBalance
                    },
                    on: { click: _vm.toggleShowBalance }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "m-icon-refresh",
                    on: { click: _vm.handleRefresh }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.userCenterConfig, function(item, index) {
            return item
              ? _c(
                  "li",
                  {
                    class: [
                      "m-list-item",
                      "m-text-main",
                      {
                        "m-list-item--active": item === _vm.current,
                        first: index === 0,
                        last: index === _vm.userCenterConfig.length - 1
                      }
                    ],
                    on: {
                      click: function($event) {
                        _vm.handleClick(index, item)
                      }
                    }
                  },
                  [
                    item === "Gifts" && _vm.giftAmount > 0
                      ? _c("em", { staticClass: "m-gift-value" }, [
                          _vm._v(
                            "\n\t\t\t" + _vm._s(_vm.formatGiftAmount) + "\n\t\t"
                          )
                        ])
                      : item == "Deposit" && !_vm.hasLogout
                      ? _c("em", [
                          _c("i", { staticClass: "m-icon--loudspeaker" }),
                          _c("span", [_vm._v("Free")])
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n\t\t" +
                        _vm._s(
                          item === "Gifts" && _vm.giftNum === 1 ? "Gift" : item
                        ) +
                        "\n\t\t"
                    ),
                    item === "Gifts" && _vm.giftNum > 0
                      ? _c("span", [
                          _vm._v("\n\t\t\t(" + _vm._s(_vm.giftNum) + ")\n\t\t")
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.hasLogout
            ? _c(
                "li",
                { staticClass: "m-list-bar", on: { click: _vm.logout } },
                [_vm._v("Log Out")]
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }