import { setGlobalHeaders } from 'fetch';
import { getLang } from 'utils/lang';

const params = {
	platform: 'web',
	ClientId: 'web',
	Channel : window.op
};

const lang = getLang();
params.language = lang;
setGlobalHeaders(params);
