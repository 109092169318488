import { isEmptyObject } from 'utils';

/*
比赛时间转成分钟
 */
export function getSeconds(time = '', secondReq = true) {
	if (!time) {
		return '';
	}

	const timeArr = time.split(':') || [];
	let ret = secondReq ? 1 : 0;

	timeArr.length = timeArr.length ? timeArr.length - 1 : 0;

	if (!timeArr.length) {
		return ret;
	}

	ret = 0;
	timeArr.reverse();
	timeArr.forEach((item, index) => {
		ret += (index === 0 ? +item : item * index * 60);
	});

	return ret || 1;
}

/*
（hover 浮层）根据不同球类，显示不同的时间信息
 */
export function getScheduleDesc(event = {}, sportId = 1, secondReq = true, rugbyStatus = false) {
 	let ret = '';

 	if (isEmptyObject(event) || !sportId) {
 		return ret;
 	}

 	switch (+sportId) {
 	// rugby
 	case 12: {
 		const playedSeconds = getSeconds(event.playedSeconds, secondReq);
 		if (rugbyStatus) {
 			const matchStatus = event.matchStatus || '';
 			ret = `${matchStatus} ${playedSeconds ? playedSeconds + '\'' : ''}`;
 		} else {
 			ret = playedSeconds ? `${playedSeconds}'` : '';
 		}

 		break;
 	}
 	case 24:
 	// Field Hockey
 	case 4:
 	// Ice Hockey
 	// basketball
 	case 2: {
 		const playedSeconds = getSeconds(event.remainingTimeInPeriod, secondReq);
 		const matchStatus = event.matchStatus || '';
 			// period = event.period || '';

 		// matchStatus = period || matchStatus.replace(/\D/g, '');
 		// matchStatus = matchStatus && `Q${matchStatus}` || '';
 		ret = `${matchStatus} ${playedSeconds ? playedSeconds + '\'' : ''}`;
 		break;
 	}
 	case 5:
 	// tennis
 	case 21:
 	// cricket
 	case 31:
 	// badminton
 	case 3:
 	// baseball
 	case 20:
 	// table tennis
 	case 37:
 	// squash
 	case 32:
 	// bowls
 	// snooker
 	case 19: {
 		ret = event.matchStatus || '';
  	  break;
 	}
 	default: { // football
 		const playedSeconds = getSeconds(event.playedSeconds, secondReq);
 		const matchStatus = event.matchStatus || '';
 		ret = `${matchStatus} ${playedSeconds ? playedSeconds + '\'' : ''}`;
 		break;
 	}
 	}

 	return ret;
}

/*
获取显示的score
 */
export function getShowScore(eventItem = {}, sportId = 1, showPoint = false) {
	if (isEmptyObject(eventItem) || !sportId) {
		return '';
	}

	// Tennis
	if (+sportId === 5) {
		return `${eventItem.setScore || ''} ${eventItem.gameScore && eventItem.gameScore.length && eventItem.gameScore[eventItem.gameScore.length - 1] || ''} ${showPoint && eventItem.pointScore || ''}`;
	}
	// Volleyball Beach Volley
	if ([23, 34].includes(+sportId)) {
		const gameScore = eventItem.gameScore && eventItem.gameScore.length ? eventItem.gameScore[eventItem.gameScore.length - 1] : '';
		return `${eventItem.setScore || ''} ${gameScore}`;
	}
	// footBall Basketball Rugby
	if ([1, 2, 12].includes(+sportId)) {
		return eventItem.setScore || '';
	}

	return eventItem.setScore || '';
}

// +254 手机号码前缀fix
export function fixPhone(phone = '') {
	const countryCode = window.countryCode;

	if (phone && !/^0/.test(phone) && countryCode === '254') {
		const temp = `0${phone}`;
		return temp.length > 18 ? temp.slice(0, 18) : temp;
	}

	return phone;
}
// 不同国家 不同联系号码
export function getContactsNumber() {
	const country = window.country;
	const numberMap = {
		ke: '0207640825',
		ng: '07008888888',
		gh: '0242426200'
	};
	return numberMap[country];
}
