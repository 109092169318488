var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.getWrapperStyle }, [
    _c("div", { staticClass: "m-pop-header" }, [
      _c("span", [_vm._v(_vm._s(_vm.contentData.title))]),
      _vm._v(" "),
      _c("i", {
        staticClass: "m-icon-close",
        attrs: { "data-action": "close", "data-ret": "close" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-pop-main" }, [
      _c("p", { domProps: { innerHTML: _vm._s(_vm.contentData.msg) } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-btn-wrapper" },
        [
          _c(
            "af-Button",
            { attrs: { "data-action": "btn", "data-ret": "ok" } },
            [_vm._v(_vm._s(_vm.contentData.okBtn || "OK"))]
          ),
          _vm._v(" "),
          _vm.contentData.cancelBtn
            ? _c(
                "af-Button",
                {
                  attrs: {
                    "data-action": "btn",
                    "data-ret": "cancel",
                    extraType: _vm.contentData.cancalBtnType || "primary"
                  }
                },
                [_vm._v(_vm._s(_vm.contentData.cancelBtn || "Cancel"))]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }