var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-page m-page--sms" },
    [
      _c("AfHeader", {
        attrs: { backIcon: _vm.backIcon, title: _vm.titleText },
        on: { back: _vm.goPrev, close: _vm.closePop }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "m-page-main" }, [
        _c("p", { staticClass: "m-text-tips" }, [
          _vm._v(
            "Please send an SMS to " +
              _vm._s(_vm.smsNumber) +
              " from " +
              _vm._s(_vm.countryCode) +
              " " +
              _vm._s(_vm.phone) +
              " with the verification code below."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "m-text-main" }, [
          _vm._v(_vm._s(_vm.smsContent))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-page-opt" },
          [
            _c(
              "p",
              {
                class: ["m-icon-check", { "m-icon-checked": _vm.isReady }],
                on: { click: _vm.handleAcccept }
              },
              [_vm._v("I have sent the requested SMS")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showError,
                    expression: "showError"
                  }
                ],
                staticClass: "m-error-tips"
              },
              [_vm._v("The checkbox above should be checked")]
            ),
            _vm._v(" "),
            _c(
              "AfButton",
              {
                attrs: {
                  type: "primary",
                  loading: _vm.loading,
                  disabled: _vm.submitStatus
                },
                on: { click: _vm.handleNext }
              },
              [_vm._v(_vm._s(_vm.btnText))]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }